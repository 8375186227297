<template>
    <div style="position: relative;">
        <!--专题活动顶部筛选区-->
         <div name="bar" v-if="hideToolbar"></div>
         <div class="bar v2-g-flex-row" name="bar" v-if="!hideToolbar">
            <div class="v2-g-flex-row v2-items-center">
                <span class="switch" @click="swich(false)" :class="!isColList ? 'sel' : ''">大图模式</span>
                <span class="switch" @click="swich(true)" :class="isColList ? 'sel' : ''">列表模式</span>
                <ul class="sx v2-g-flex-row" v-if="isGlobalSearch" style="margin:0 20px">
                    <li @click="sortBySale(1)" :class="sortIndex == 1 ? 'sel' : ''">
                        <span>综合</span>
                    </li>
                    <li @click="sortBySale(3)" class="v2-g-flex-row" :class="sortIndex == 3 ? 'sel' : ''">
                        <span>销量</span>
                        <span class="v2-g-flex-column icc">
                            <i class="up" :class="sortIndex == 3 && sortSaleAsc ? 'sel' : ''"></i>
                            <i class="down" :class="sortIndex == 3 && !sortSaleAsc ? 'sel' : ''"></i>
                        </span>
                    </li>
                    <li @click="sortBySale(2)" class="v2-g-flex-row" :class="sortIndex == 2 ? 'sel' : ''">
                        <span>价格</span>
                        <span class="v2-g-flex-column icc">
                            <i class="up" :class="sortIndex == 2 && sortPriceAsc ? 'sel' : ''"></i>
                            <i class="down" :class="sortIndex == 2 && !sortPriceAsc ? 'sel' : ''"></i>
                        </span>
                    </li>
                    <li @click="sortBySale(4)" :class="sortIndex == 4 ? 'sel' : ''">
                      <span>活动</span>
                    </li>
                </ul>

                <!--基药专区筛选条件-->
                <div class="cm-sel-box" v-if="type == 9">
                    <b-select v-model="jyValue" placeholder="请选择" expanded @input="jyChange($event, 2)">
                        <option :value="item.value" :key="index" v-for="(item, index) in jyMode">
                            {{ item.label }}
                        </option>
                    </b-select>
                </div>

                <div v-if="isNotAct && type && type != 20" style="margin:0 20px">
                    <Checkbox :isCheck="isSelectAct" @click="isSelectAct = !isSelectAct; flterAct(isSelectAct)">促销活动商品</Checkbox>
                </div>
                <span class="bal-act">
                  本月统计：参与活动次数<i>{{ actBal.activityCount }}</i>
                  账户金额<i>{{ actBal.totalBalanceAmount }}</i>
                </span>
            </div>
            <div>
                <div v-if="!isGlobalSearch && !hideSearch && type != 20" class="search">
                  <input type="text" placeholder="在当前专区内搜索" v-model="productName" @keyup.enter="searchProduct" />
                  <span class="btn" @click="searchProduct">
                    <i class="iconfont icon-search"></i>搜索
                  </span>
                </div>
                <div class="page v2-g-flex-row">
                  <!--<span>-->
                  <!--  共<i class="blue">{{ pageInfo.total }}</i>个商品-->
                  <!--  共<i class="blue">{{totalPage }}</i>页-->
                  <!--</span>-->
                  <span class="in v2-g-flex-row">
                    <i class="iconfont icon-arrow-left v2-pointer" @click="toPre"></i>
                    <input v-model="pageInfo.pageNum" disabled/>
                    <i class="iconfont icon-arrow-right v2-pointer" @click="toLast"></i>
                  </span>
                </div>
            </div>
        </div>

        <!--医保专区-->
        <div class="jyBox" v-if="type == 7">
            <b-select v-model="ybValue" placeholder="请选择" expanded @input="jyChange($event, 1)">
                <option :value="item.value" :key="index" v-for="(item, index) in ybMode">
                    {{ item.label }}
                </option>
            </b-select>
            <Checkbox :isCheck="isSelectYbSort" @click="isSelectYbSort = !isSelectYbSort; flterYbSort(isSelectYbSort)">医保价差排序</Checkbox>
        </div>
        <p class="xsTips" v-if="type == 9">学术商品：点击商品详情查看学术资料！</p>
        <div class="list v2-g-flex-row " name="list">
          <div class="nodata" v-if="list.length == 0 && isShowEmptyState">
            <img src="@/assets-v2/images/nodata.png" />
            <span>暂无相关商品</span>
          </div>

            <!--大图模式-->
            <template v-else-if="!isColList">
                <div class="product-wrapper" v-for="(item, index) in list" :key="item.productId">
                  <!--控销的组件-历史产品列表-->
                  <historicalProductList v-if="productListType === 'controlPin'" :product="item" :detailLeft="(index + 1) % 5 == 0 && index > 0"
                                         @showSimilar="showSimilarDlg(item)" @getGoodsList="getGoodsList" />

                  <!--新的产品列表-->
                  <ProductItem v-else :product="item" :detailLeft="(index + 1) % 5 == 0 && index > 0"
                        @showSimilar="showSimilarDlg(item)" @getGoodsList="getGoodsList" />
                </div>
            </template>

            <!--列表模式-->
            <template v-else>
                <div class="t v2-g-flex-row v2-g-flex-space-between">
                    <div class="product">商品</div>
                    <div class="price">单价</div>
                    <div class="num">数量</div>
                    <div class="op">操作</div>
                </div>
                <div class="product-wrapper-col" v-for="(item, index) in list" :key="item.productId">
                    <ProductItemCol :product="item" :detailLeft="index % 4 == 0 && index > 0"
                        @showSimilar="showSimilarDlg(item)"></ProductItemCol>
                </div>
            </template>
        </div>

        <div class="pager">
            <Pager :pageInfo="pageInfo" v-on:getdata="getDataByPage"></Pager>
        </div>
        <Dialog title="相似商品" :show="isShowSimilar" width="1250px" height="80%" @close="(isShowSimilar = false)">
            <SimilarList :id="productAliasCode" :productCode="productCode"></SimilarList>
        </Dialog>
    </div>
</template>
<style scoped lang="less">
.bar {
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    background: #F1F1F4;

    .switch {

        height: 26px;
        padding: 0 15px;
        line-height: 26px;
        background: #FFFFFF;
        border-radius: 13px;

        &.sel {
            background: #3875F6;
            color: #fff;
        }
    }

    .sx {
        background-color: #fff;
        border-radius: 5px;
        align-items: center;
        height: 26px;

        li {
            padding: 0 5px;
            cursor: pointer;

            &::after {
                content: "";
                padding: 0 5px;
                border-right: 1px solid #ededed;
            }

            &:nth-last-child(1) {
                &::after {
                    border-right: none;
                }
            }

            &.sel {
                color: #3875F6;
            }

            .icc {
                justify-content: center;

                i {
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;

                    &.up {
                        margin-bottom: 1px;
                        border-bottom: 7px solid #666;

                        &.sel {
                            border-bottom: 7px solid #3875F6;
                        }
                    }

                    &.down {
                        border-bottom: none;
                        border-top: 7px solid #666;
                        margin-top: 1px;

                        &.sel {
                            border-top: 7px solid #3875F6;
                        }
                    }
                }
            }
        }
    }

    .bal-act {
        color: #333;
        font-weight: 400;
        font-size: .875rem;
        margin-left: 10px;

        i {
            color: red;
            padding: 0 5px;
            font-style: normal;

            &:nth-child(1) {
                margin-right: 20px;
            }
        }
    }

    .search {
        display: inline-block;

        width: 260px;
        height: 30px;
        line-height: 1;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 2px;
        font-size: .6rem;
        position: relative;
        text-align: left;
        padding-left: 5px;
        padding-right: 50px;

        .btn {
            cursor: pointer;
            color: #3875f6;
            position: absolute;
            right: 10px;
          top: 50%;
          transform: translate(0, -50%);
          display: flex;
          align-items: center;

            &:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
            }
        }

        input {
            border: none;
            height: 100%;
            width: 100%;
        }

    }

    .page {
        margin-left: 20px;
        margin-right: 5px;
        height: 24px;
        display: inline-block;

        .blue {
            color: #3875F6;
            font-style: normal;
        }

        .in {
            background-color: #fff;
            line-height: 24px;
            display: inline-block;
            height: 100%;

            i {
                font-size: 1.2rem;
            }

        }

        input {
            border: none;
            width: 20px;
            vertical-align: top;
            height: 100%;
            text-align: center;
        }
    }

}
.xsTips{
  font-weight: 400;
  font-size: 12px;
  color: #FF6B00;
  text-align: center;
  padding-top: 10px;
}
.list {
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;

    .nodata {
        margin: 50px auto;
        position: relative;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            color: #999999;
        }
    }

    .product-wrapper {
        margin-right: 17.5px;
        margin-bottom: 24px;

        &:nth-child(5n+5) {
            margin-right: 0;
        }


    }

    .product-wrapper-col {
        width: 100%;
        border: 1px solid #ededed;
        border-bottom: none;

        &:nth-last-child(1) {
            border: 1px solid #ededed;
        }
    }

    .t {
        width: 100%;
        height: 48px;
        background: #F3F3F3;
        align-items: center;
        color: #333333;

        .product {
            width: 350px;
            text-align: center;
        }

        .price {
            width: 200px;
            text-align: center;
        }

        .num {
            width: 150px;
            text-align: center;
        }

        .op {
            width: 200px;
        }
    }

}
.jyBox{
  display: flex;
  padding: 10px 0 0 0;
  ::v-deep .control{
    margin-right: 10px;
  }
}
.pager {
    margin-bottom: 20px;
}
.cm-sel-box{
  line-height: 36px;
}
</style>
<script>
import ProductItem from './product-item';
import ProductItemCol from './product-col-item'
import Pager from '../pager/index'
import { mapActions, mapGetters } from "vuex";
import Checkbox from '../checkbox/index'
import Dialog from '../v2-dialog/index'
import SimilarList from './similar-product-list'
import historicalProductList from '@/components-v2/product/historicalProductList.vue'

export default {
    components: {
        ProductItem, ProductItemCol, Pager, Checkbox, Dialog, SimilarList, historicalProductList
    },
    data() {
        return {
            productName: "",
            isColList: false, //是否是列表模式
            isSwitchTab: true,
            sortPriceAsc: true, //是否升序
            sortSaleAsc: true, //销量升序列
            sortIndex: 1,//排序
            isSelectAct: false,//是否选择促销商品
            isSelectYbSort:false,//医保专区是否选择医保价差排序
            isShowSimilar: false, //是否显示相识商品
            productAliasCode: "",
            jyMode: [
              {value: 0, label: "全部"},
              {value: 4, label: "注射剂"},
              {value: 7, label: "口服液"},
              {value: 6, label: "中成药"},
              {value: 8, label: "学术"}
            ],
            jyValue:0,
            ybValue:0,
            ybMode:[{
                value:0,
                label:"全部医保"
            },{
                value:1,
                label:"甲类医保"
            },{
                value:2,
                label:"乙类医保"
            },{
                value:3,
                label:"丙类医保"
            },{
                value:4,
                label:"门统医保"
            }],
            type:null,
            productCode: ''
        };
    },
    props: {
        pageInfo: {
            type: Object
        },
        list: { type: Array }, //数据
        hideSearch: { type: Boolean }, //是否隐藏查询框
        isGlobalSearch: { type: Boolean },//是否全局搜索，
        hideToolbar:{type:Boolean}, //是否隐藏大图列表切换栏目，爆款页面需要
        isShowEmptyState: {
          type: Boolean,
          default: true
        },
        productListType: {
          type: String,
          default: ''
        }
    },
    created() {
        this.type = this.$route.params.id
        this.getAct();
    },
    watch: {
        "$route.params.id": function () {
            this.type = this.$route.params.id
            this.productName = ""; //清除专区内搜索关键词
        }
    },
    computed: {
        ...mapGetters({
            actBal: 'actBal'
        }),
        totalPage: function () {
            if (this.pageInfo.pageSize == 0) {
                return 0;
            }
            let c = parseInt(this.pageInfo.total / this.pageInfo.pageSize);

            return this.pageInfo.total % this.pageInfo.pageSize > 0 ? c + 1 : c;

        },
        isNotAct:function(){
            return this.$route.name!='v2-activity'
        }
    },

    methods: {
        ...mapActions(['getActBal']),
        // 2诊卫、1医保选择
        jyChange(e,type) {
          let params = {
            pageNum: 1,
            activity: this.isSelectAct ? 1 : 0,  // 是否常购
            searchCriteria: this.productName
          }
          if (type === 1){
              params.insuranceCode = this.ybValue
              params.medicalClassSort = this.isSelectYbSort ? 1 : 0
          }
          if (type === 2){
              params.medicalClass = this.jyValue
          }
          this.$emit('getdata', params);
        },
        //页面内搜索
        searchProduct() {
            let params = {
                pageNum: 1,
                activity: this.isSelectAct ? 1 : 0,  // 是否常购
                medicalClass: this.jyValue,
                insuranceCode: this.ybValue,
                medicalClassSort: this.isSelectYbSort ? 1 : 0,
                searchCriteria: this.productName
            }
            this.$emit('getdata', params);
        },
        // 医保专区-门统选择
        swichChange(flag) {
          this.isSwitchTab = flag;
          let params = {
            pageNum: 1,
            activity: this.isSwitchTab ? '' : 3,  // 3是门统，空是全部
            searchCriteria: this.productName
          }
          this.$emit('getdata', params);
        },
        getAct() {
            if (this.actBal.activityCount == undefined) {
                this.getActBal();
            }
        },
        getGoodsList() {
          this.$emit('getGoodsList')
        },
        showSimilarDlg(item) {
            this.productAliasCode = item.productAliasCode.toString();
            this.productCode = item.productCode;
            this.isShowSimilar = true;
        },
        // 分页功能
        getDataByPage(pageNum) {
          // sortType 排序规则 1.综合 2.销量升序 3.销量降序 4.价格升序 5.价格降序 6.活动
          let sortType;
          if (this.sortIndex == 1) {
            sortType = 1;
          } else if (this.sortIndex == 2) {
            sortType = this.sortPriceAsc ? 4 : 5;
          } else if (this.sortIndex == 3) {
            sortType = this.sortSaleAsc ? 2 : 3;
          } else if (this.sortIndex == 4) {
            sortType = 6;
          }
          let params = {
            pageNum: pageNum,
            activity: this.isSelectAct ? 1 : 0,  // 是否常购
            medicalClass: this.jyValue,
            insuranceCode: this.ybValue,
            medicalClassSort: this.isSelectYbSort ? 1 : 0,
            searchCriteria: this.productName,
            sortType: sortType
          }
          this.$emit('getdata', params);
          this.scrollTo();
        },
        scrollTo() {
            document.getElementsByName('bar')[0].scrollIntoView(
                {
                    behavior: "smooth",  // 平滑过渡
                    block: "center"    // 上边框与视窗顶部平齐
                });
        },
        //上一页
        toPre() {
            if (this.pageInfo.pageNum > 1) {
                this.getDataByPage(parseInt(this.pageInfo.pageNum) - 1)
            }
        },
        //下一页
        toLast() {
            let c = parseInt(this.pageInfo.total / parseInt(this.pageInfo.pageSize));
            c = this.pageInfo.total % parseInt(this.pageInfo.pageSize) > 0 ? c + 1 : c;
            if (this.pageInfo.pageNum < c) {
                this.getDataByPage(parseInt(this.pageInfo.pageNum) + 1)
            }
        },
        //大图列表转换
        swich(flag) {
          this.isColList = flag;
        },
        sortBySale(type) {
          this.sortIndex = type;
          if (this.sortIndex == 2) {
            this.sortPriceAsc = !this.sortPriceAsc;
          } else if (this.sortIndex == 3) {
            this.sortSaleAsc = !this.sortSaleAsc;
          } else {
            this.sortSaleAsc = false;
            this.sortPriceAsc = false;
          }

          // sortType 排序规则 1.综合 2.销量升序 3.销量降序 4.价格升序 5.价格降序 6.活动
          let sortType;
          if (this.sortIndex == 1) {
            sortType = 1;
          } else if (this.sortIndex == 2) {
            sortType = this.sortPriceAsc ? 4 : 5;
          } else if (this.sortIndex == 3) {
            sortType = this.sortSaleAsc ? 2 : 3;
          } else if (this.sortIndex == 4) {
            sortType = 6;
          }
          let params = {
            pageNum: 1,
            sortType: sortType
          }
          this.$emit('getdata', params);
        },
        // 综合/活动/促销活动商品
        flterAct() {
            let params = {
                pageNum: 1,
                activity: this.isSelectAct ? 1 : 0,  // 是否常购
                medicalClass: this.jyValue,
                insuranceCode: this.ybValue,
                medicalClassSort: this.isSelectYbSort ? 1 : 0,
                searchCriteria: this.productName
            }
          this.$emit('getdata', params);
        },
        //医保价差排序
        flterYbSort(){
            let params = {
                pageNum: 1,
                activity: this.isSelectAct ? 1 : 0,  // 是否常购
                medicalClass: this.jyValue,
                insuranceCode: this.ybValue,
                medicalClassSort: this.isSelectYbSort ? 1 : 0,
                searchCriteria: this.productName
            }
            this.$emit('getdata', params);
        }
    }
}
</script>
