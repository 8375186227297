import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop1'), _c('PageTop'), _c('div', {
    staticClass: "v2-g-w1200"
  }, [_c('div', {
    staticClass: "filter_btn_box_item"
  }, [_c('b-select', {
    attrs: {
      "placeholder": "请选择",
      "expanded": ""
    },
    on: {
      "input": _vm.csChange
    },
    model: {
      value: _vm.csValue,
      callback: function callback($$v) {
        _vm.csValue = $$v;
      },
      expression: "csValue"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("全部厂家")]), _vm._l(_vm.selector, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": item.supplierCode
      }
    }, [_vm._v(" " + _vm._s(item.supplierName) + " ")]);
  })], 2), _vm.currentSupplierData ? _c('div', {
    staticClass: "spplierInfo"
  }, [_c('img', {
    attrs: {
      "src": _vm.currentSupplierData.icon
    }
  }), _c('div', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('p', [_vm._v("控销厂家：" + _vm._s(_vm.currentSupplierData.name))]), _c('p', [_vm._v("商品个数：" + _vm._s(_vm.pageInfo.total))])]), _c('div', {
    staticClass: "tag-content",
    on: {
      "click": function click($event) {
        _vm.dl_modal = true;
      }
    }
  }, [_vm._v("代理联系方式")])]) : _vm._e(), _c('div', {
    staticClass: "search-box"
  }, [_c('div', {
    staticClass: "search-inp"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchVal,
      expression: "searchVal"
    }],
    attrs: {
      "type": "text",
      "placeholder": "请输入控销厂家或控销产品名称"
    },
    domProps: {
      "value": _vm.searchVal
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchData.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchVal = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "search-img"
  }, [_c('span', {
    staticClass: "iconfont icon-search",
    on: {
      "click": _vm.searchData
    }
  }, [_vm._v(" 搜索")])])])], 1), _c('ProductList', {
    attrs: {
      "productListType": "controlPin",
      "list": _vm.list,
      "pageInfo": _vm.pageInfo,
      "hideToolbar": true
    },
    on: {
      "getdata": _vm.getDataByPage,
      "getGoodsList": _vm.getData
    }
  })], 1), _c('PageBottom'), _c('el-dialog', {
    attrs: {
      "visible": _vm.dl_modal,
      "width": "327px",
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dl_modal = $event;
      }
    }
  }, [_c('supplier-ruler-pop', {
    attrs: {
      "rulerData": _vm.currentSupplierData
    },
    on: {
      "popStatus": _vm.popStatus
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };